.form-select:focus {
  border-color: var(--bs-border-color) !important;
  outline: 0;
  box-shadow: none !important;
}
.form-select {
  --bs-form-select-bg-img: url(../../assets/svgs/select-icon.svg) !important;
}
.select-label {
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
  color: var(--light-black-color);
  font-family: var(--main-font);
  margin-top: 21px;
}
.span-star {
  color: var(--blue-color);
  font-size: 14px;
}
