.users-add-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-bottom: 30px;
}
.sales-representative {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--black-colors);
  font-family: var(--main-font);
}

.error-message {
  color: red;
}