.button {
  background-color: var(--orange-color);
  font-family: var(--main-font);
  border: none;
  outline: none;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--white-color);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
