.user-profile-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-profile-name {
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  font-family: var(--main-font);
  color: var(--dark-black-color);
}
.user-profile-mail {
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  font-family: var(--main-font);
  color: var(--dark-black-color);
}
.user-profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-profile-content-wrapper {
  border: 1px solid var(--light-sky-color);
  border-radius: 20px;
  padding: 40px;
  min-height: 80vh;
}
.user-profile-inputs-wrapper {
  margin-top: 20px;
}
.admin-save-button {
  margin-left: auto;
  align-self: flex-end;
  margin-top: 7rem;
  width: 170px;
}
.sold-tickets-card-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}
