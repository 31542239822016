.category-card-container {
  background-color: var(--light-green-color);
  width: 100%;
  border-radius: 10px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  margin-bottom: 20px;
}
.category-card-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  font-family: var(--main-font);
  color: var(--white-color);
}
