.login-container {
  background-color: var(--orange-color);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-card {
  width: 440px;
  height: 560px;
  border-radius: 38px;
  background: var(--white-color);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  padding: 40px;
}
.logo-wrapper {
  display: flex;
  justify-content: center;
}
.login-title {
  font-family: var(--main-font);
  color: var(--light-black-color);
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-top: 15px;
  margin-bottom: 48px;
}
.login-button-wrapper {
  margin-top: 40px;
}
