.tour-card-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.scroll-wrapper {
  overflow-x: auto;
}
.sales-analysis-heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--black-colors);
  font-family: var(--main-font);
  margin: 0px 0px 25px 22px;
}
.vertical-border {
  border-right: 1px solid var(--light-sky-color);
  height: 90vh;
  height: 90%;
}
.radio-button-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.radio-button-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--black-colors);
  font-family: var(--main-font);
}
.form-check-input:checked {
  background-color: var(--orange-color) !important;
  border-color: var(--orange-color) !important;
}
.form-check-input:focus {
  border-color: var(--orange-color) !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.view-all-tours {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;
  color: var(--orange-color);
  font-family: var(--main-font);
  border: none;
  outline: none;
  background-color: var(--white-color);
  text-decoration: underline;
}
.viewl-all-tours-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 20px;
}
.horizontal-border {
  border-bottom: 1px solid var(--light-sky-color);
  margin-top: 30px;
}
.error-message-tours {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--dark-orange-color);
  font-family: var(--main-font);
  width: 100%;
  margin-top: 10vh;
}
.active-and-upcoming-loader {
  margin-top: 11vh;
  text-align: center;
}
