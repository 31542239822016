table {
  border-collapse: collapse;
  width: 100%;
}

table td,
#customers th {
  padding: 15px;
}

table td {
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  font-family: var(--main-font);
}

table tr {
  background-color: #f4fbff;
  text-align: center;
}

table th {
  text-align: center;
  background-color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--black-colors);
  font-family: var(--main-font);
}

td:first-child {
  border-radius: 15px 0 0 15px;
}

td:last-child {
  border-radius: 0 15px 15px 0;
}

.spacer {
  margin: 15px 0;
}

.user-table-container {
  min-height: 100%;
  height: 70vh;
  overflow-y: auto;
}

.user-table-container::-webkit-scrollbar {
  width: 0px;
}

#customers th {
  position: sticky;
  top: 0;
  background-color: var(--white-color);
  border-radius: 0px;
  z-index: 1;
}

.users-table-num {
  color: var(--off-white-color);
}

.users-table-name {
  font-weight: 600;
  color: var(--dark-gray-colors);
}

.users-table-email {
  font-weight: 600;
  font-size: 14px;
  color: var(--dark-gray-colors);
}

.users-table-revenue {
  font-weight: 800;
  font-size: 16px;
  color: var(--blue-color);
}

.users-table-tickets {
  font-weight: 600;
  font-size: 14px;
  color: var(--orange-color);
}

.dropdown-item {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 5px 15px !important;
}

.dropdown-menu {
  border: none !important;
  border: 1px solid var(--light-gray-color) !important;
}

.dropdown-item:active {
  background-color: var(--orange-color) !important;
}

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}