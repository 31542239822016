.main-container {
  display: flex;
  height: 100%;
}
.body-container {
  width: 100%;
  padding: 25px 30px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  overflow: auto;
  background-color: var(--white-color);
}
