* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
.app {
  height: 100vh;
  background-color: var(--orange-color);
}

/* Colors */

:root {
  --orange-color: #ff5c00;
  --white-color: #ffff;
  --light-black-color: #2c374f;
  --light-gray-color: #caced8;
  --dark-black-color: #1f192f;
  --gary-color: #54657e;
  --light-sky-color: #ccebfe;
  --light-green-color: #17b890;
  --extra-light-green: #b8ffed;
  --black-colors: #04103b;
  --dark-gray-colors: #797d8c;
  --blue-color: #0075e2;
  --dark-orange-color: #ff3a29;
  --off-white-color: #d0d0d0;
}

:root {
  --main-font: "Nunito", sans-serif;
}
